.about-us {
    background-color: #9892d5;
    margin-top: -.5em;
}

.news {
    background-color: #61c7d1;
}

.map {
    background-color: #7fbd67;
}

.trajectory {
    background-color: #dac17b;
}

.gallery {
    background-color: #d29a83;
}

.contact{
    background-color: #d96a70;
}

/*    Home    */

.home img {
    width: 100%;
    margin: 0;
}

.logo {
    height: 20em;
    position:relative;
    margin-top: 3rem;
}