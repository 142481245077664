.aboutOrnella {
    padding-top: 2rem;
    & h4 {
        margin-left: 0;
        font-weight: 600;
        border-bottom: lightgray thin solid;
        &:before {
            color:#c5c2e7;
        }
    }
    & h5 {
        font-size: 1.8rem;
    }
}

.aboutOrnella h2 {
    letter-spacing: .07rem;
    margin: 0;
    color: black;
}

.yoga-type{
    margin-top: 2em;
    & a:hover {
        color: #9892d5;
    }
    & img {
        width: 10rem;
        height: 10rem;
        filter: grayscale(100%);
        z-index: 1;
    }
}

.yoga-type-image{
    position: relative;
    overflow: hidden;
    margin-right: 2rem;
}

.yoga-type:hover .yoga-type-image:before {
    top: 0;
}

.selected {
    color: #9892d5;
    & .yoga-type-image:before {
        top:0;
    }
}

.yoga-type-image:before{
    content:"";
    width:100%;    
    height: 10rem;
    background-color: rgba(152, 146, 213,0.5);
    display: block;
    position: absolute;
    transition: 0.5s;
    top: -10rem;
    z-index: 10;
}

 .aboutOrnella .description {
    background-color: #c5c2e7;
    font-size: 1em;
    padding: 1.2rem !important;
    min-height: 765px;
    position: relative;
    & div {
        position: absolute;
        top: 0;
        display: none;
    }
    & div:nth-child(1) {
        display: inherit;
    }
}