.gallery-message {
    text-align: center;
}

.gallery-image{
    /*height: 9rem;*/
    overflow: hidden;
    margin-top: 1em;
}

.gallery-image a {
  text-align: center;
  overflow: hidden;
  position: relative;
}

.showimg {
  background-position: center;
  background-size: cover;
  height: 100%;
  -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");

}

.gallery-image a h1 {
  font-size: 1.4rem;
  position: absolute;
  width: 100%;
  height: 10rem;
  padding: 1rem 0.5rem;
  color: #fff;
  text-align: center;
  transition: top 0.35s;
  z-index: 101;
  top: -10rem;
}

.gallery-image a:after {
  content: "";
  width: 100%;
  height: 20rem;
  background-color: rgba(0,0,0, 0.3);
  position: absolute;
  z-index: 100;
  top: -20rem;
}

.gallery-image a:hover {
    filter: grayscale(0);
}

.gallery-image a:hover h1 {
  top: 0rem;
}

.gallery-image a:hover:after {
  top: 0rem;
}

.gallery-text p{
    color: #fff;
    padding: 1rem;
    font-family: 'denseregular';
    font-size: 1.6rem;
    line-height: 1.6rem;
}

.gallery-text {
    height: 9rem;
    background-color: #d29a83;
    margin-top: 1em;
    overflow: hidden;
}