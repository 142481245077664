.cont-logo-footer svg{
    height: 10rem;
    width: 10rem;

}

.cont-logo-footer svg path{
    fill: #fff;
}

footer ul{
    display: flex;
    margin-top: 4rem;
}

footer{
    padding: 0;
    margin: 0;
    color: #fff;
    text-align: center
}
footer .row{
    margin-bottom: 0;
}

footer a{
    color: #fff;
    font-size: 1.5rem;
}
