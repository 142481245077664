html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

.fonts-loaded body {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #7b7b7b;
}

@viewport {
  width: device-width;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'denseregular';
    letter-spacing: 0.1rem;
}

h2 {
  font-size: 3rem;
}

p {
  /*margin: 0;*/
  letter-spacing: 0.12rem;
}

a{   
    color:#7b7b7b;
    font-family: 'denseregular';
}

/* materialize changes */
.container {
  width: 80% !important;
}