.navbar{
    position: fixed;
    z-index: 3;
    top: 3rem;
    left: 3rem;
    width: 10rem;
    max-height: 0;
}


.navbar .icon-ornella{
    border-bottom:4px solid #9892d5 !important;
    &:before {
        color:#9892d5 !important;
    }
}
.navbar .icon-novedades{
    border-bottom:4px solid  #61c7d1 !important;
    &:before {
        color:#61c7d1 !important;
    }
}
.navbar .icon-clases{
    border-bottom:4px solid #7fbd67 !important;
    &:before {
        color:#7fbd67 !important;
    }
}
.navbar .icon-lugares{
    border-bottom:4px solid #dac17b !important;
    &:before {
        color:#dac17b !important;
    }
}
.navbar .icon-galeria{
    border-bottom:4px solid #d29a83 !important;
    &:before {
        color:#d29a83 !important;
    }
}
.navbar .icon-contacto{
    border-bottom:4px solid #d96a70 !important;
    &:before {
        color:#d96a70 !important;
    }
}

.menu{
    border: solid #7b7b7b; 
    height: 4.3rem;
    width: 4.5rem;
    border-radius: 50%;
    display: block;
      
}

.icon-menu{
    font-size: 3rem;
    color: #7b7b7b;
}



.fixed-action-btn ul {
    position: relative; 
    bottom: 0;
    /*margin: 1rem 0  0 0.5rem;*/
    text-align: inherit;    
}

.fixed-action-btn ul a.btn-floating {
    opacity: 0;
    border-radius: 0;
    border-bottom: solid;
    width: 10rem;
    /*background-color: #fff;*/
    box-shadow: none;
    font-size: 1.8rem;
    &:before {
        font-size:1.6rem;
        margin-right: 1rem;
    }
}

.btn-floating:hover {
    background-color: transparent;
}

.btn:focus, .btn-large:focus, .btn-floating:focus {
    background-color: transparent; 
}


.btn-floating{
    overflow: visible;
    color: #7b7b7b;
    background-color: transparent;
}