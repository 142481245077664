.news-photo{
    border-top-style: solid;
    border-top-width: 1.5em;
    border-top-color: #61c7d1;
    border-bottom-style: solid;
    border-bottom-width: 1.5em;
    border-bottom-color: #61c7d1; 
    height: auto;
    width: auto;
}
.news-card article{
    margin-top: 1rem;
    & h1 {
        margin-top: 0;
        margin-bottom: 0.4rem;
        line-height: 1.4rem;
    }
    & h1:before {
        color: #61c7d1;
    }
    & .read-more {
        font-size: 2.3rem;
        height: inherit;
        line-height: inherit;
        &:before {
            font-size:1.6rem;
            padding-right: 1rem;
        }
    }
}

.card{
    box-shadow: none;
    position: relative;
}

.card-info{
    background-color: #61c7d1;
    height: 3em;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.card .card-content {
    padding: 0;

}

.news-card article{
    border-right: 1px solid #ccc;
    padding: 1 .7rem 1 .7rem;
}

.news-card article:nth-child(3n){
    padding: 1 .7rem 1 .7rem;
    border-right: 1px solid #fff;

}

.btn-large {
    text-decoration: none;
    color: #fff;
    background-color: #53ddeb;
    text-align: center;
    transition: .2s ease-out;
    cursor: pointer;
    text-transform: none;
    height: 3em;
    line-height: 3em;
    box-shadow: none;
}


.card-info a:hover {
    background-color: #61c7d1;
    box-shadow: none;

}

.card-info a{
    
}

.cont-more-news{
    text-align: center;
    margin-bottom: 1rem;
}

.more-news{
    background-color:  #61c7d1;
    font-size: 3rem;
    color: #fff;
    padding: 0.2rem 1.8rem;
    text-align: center;
    letter-spacing:0.2rem;
}

.slider {
    z-index: -1;
}