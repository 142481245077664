/*                  DIVISORES                   */
.parallax img{
    /*filter: grayscale(100%);*/
    /*width: 100%;*/
}

.divisor {
    height: 6em;
    /*margin-top: 2rem;*/
}

.divisor h1 {
    color: white;
    letter-spacing: .1em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    &:before {
        margin-left:0;
        margin-right:1rem;
    }
}


/*                      RESOLUCIÓN 600PX                    */

@media(max-width: 600px){

    .divisor {
        height: 5em;

    }

    .divisor h1 {
        margin-left: 1em;
        color: white;
        letter-spacing: .1em;
        margin-top: 0;
        margin-bottom: 0;

    }        

    .news-card article{
        padding: 1 .7rem 1 .7rem;
    }

}


.redes-art {
    font-size: 2.6rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    & a {
        display: block;
    }
    & a:nth-child(1){
        color:#9892d5;
    }
    & a:nth-child(2){
        color:#7fbd67;
    }
    & a:nth-child(3){
        color:#dac17b;
    }
    & a:nth-child(4){
        color:#d29a83;
    }
}