.contact-us{
    margin-top: 2em;
    color:#7b7b7b;
    font-family: 'denseregular';
    font-size: 1.5rem;
    & p {
        margin: 0;
    }
}

.section-contact form{
    padding-top: 1rem !important;
}

.my-data li{
    margin-top: 1em;    
}

.my-data span{
    font-size: 1.1em;

}

.my-data li i{
    margin-right: 1rem;
}

label {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
}

input[type=text]{
    background-color: #f2ccce;
    height: 2.6rem;
    margin: 0 0 0.5em 0;
    font-size: 1.4rem !important;
}
textarea{
    background-color: #f2ccce !important;
    height: 10rem;
    border: 0;
    font-size: 1.4rem;
}

input[type=text]:focus:not([readonly]){
    border-bottom: 0; 
    box-shadow: 0; 
    border-bottom-color:transparent;
    box-shadow: 0 1px 0 0 transparent;
}

.section-contact button {
    background-color: #d96a70;
    box-shadow: none;
    text-transform: inherit;
    border-radius: 0;
    &:before {
        margin-right:1rem;
    }
    &:hover {
        background-color: #f2ccce;
        box-shadow: none;
    }
}


.social-net {
    & li {
        margin-bottom: 0.2rem;
        display: inline-block;
        /*display: table-cell;*/
        width: 100%;
    }
    & i {
        font-size: 1.7rem;
        height: 3.2rem;
        width: 3.2rem;
        display: inline-block;
        margin-right: 1rem;
        color: #fff;
        text-align: center;
        padding-top: 0.5rem;
        float: left;
    }
    & a{
        font-size: 1.5rem;
        float: left;
        margin-top: 0.6rem;
    }
}

.personal-info {
    & a:hover {
        color: #d96a70
    }
}


#msg{
  height: 64px;
}

#msg-all{
  display: none;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 20px;
  color: red;
}

.backGload{
  background: url('../media/loading.gif') no-repeat center;
  margin: 2rem 0;
}

.contact-error {
  -webkit-box-shadow: inset 0 0 2px red !important;
  -moz-box-shadow: inset 0 0 2px red !important;
  box-shadow: inset 0 0 2px red !important;
}


.social-net li:nth-child(1) i {
    background-color: #457e9b;
    overflow: hidden;
    text-shadow: 3px 3px 0 #406a7a , 
    4px 4px 0 #406a7a , 
    5px 5px 0 #406a7a , 
    6px 6px 0 #406a7a , 
    7px 7px 0 #406a7a , 
    8px 8px 0 #406a7a , 
    9px 9px 0 #406a7a , 
    10px 10px 0 #406a7a , 
    11px 11px 0 #406a7a , 
    12px 12px 0 #406a7a , 
    13px 13px 0 #406a7a , 
    14px 14px 0 #406a7a , 
    15px 15px 0 #406a7a , 
    16px 16px 0 #406a7a , 
    17px 17px 0 #406a7a , 
    18px 18px 0 #406a7a , 
    19px 19px 0 #406a7a , 
    20px 20px 0 #406a7a , 
    21px 21px 0 #406a7a , 
    22px 22px 0 #406a7a , 
    23px 23px 0 #406a7a , 
    24px 24px 0 #406a7a , 
    25px 25px 0 #406a7a , 
    26px 26px 0 #406a7a , 
    27px 27px 0 #406a7a , 
    28px 28px 0 #406a7a , 
    29px 29px 0 #406a7a , 
    30px 30px 0 #406a7a ,
    31px 31px 0 #406a7a , 
    32px 32px 0 #406a7a , 
    33px 33px 0 #406a7a , 
    34px 34px 0 #406a7a , 
    35px 35px 0 #406a7a , 
    36px 36px 0 #406a7a , 
    37px 37px 0 #406a7a , 
    38px 38px 0 #406a7a , 
    39px 39px 0 #406a7a , 
    40px 40px 0 #406a7a ;
}

.social-net li:nth-child(2) i {
    background-color: #62afd1;
    overflow: hidden;
    text-shadow: 3px 3px 0 #5393aa , 
    4px 4px 0 #5393aa , 
    5px 5px 0 #5393aa , 
    6px 6px 0 #5393aa , 
    7px 7px 0 #5393aa , 
    8px 8px 0 #5393aa , 
    9px 9px 0 #5393aa , 
    10px 10px 0 #5393aa , 
    11px 11px 0 #5393aa , 
    12px 12px 0 #5393aa , 
    13px 13px 0 #5393aa , 
    14px 14px 0 #5393aa , 
    15px 15px 0 #5393aa , 
    16px 16px 0 #5393aa , 
    17px 17px 0 #5393aa , 
    18px 18px 0 #5393aa , 
    19px 19px 0 #5393aa , 
    20px 20px 0 #5393aa , 
    21px 21px 0 #5393aa , 
    22px 22px 0 #5393aa , 
    23px 23px 0 #5393aa , 
    24px 24px 0 #5393aa , 
    25px 25px 0 #5393aa , 
    26px 26px 0 #5393aa , 
    27px 27px 0 #5393aa , 
    28px 28px 0 #5393aa , 
    29px 29px 0 #5393aa , 
    30px 30px 0 #5393aa ,
    31px 31px 0 #5393aa , 
    32px 32px 0 #5393aa , 
    33px 33px 0 #5393aa , 
    34px 34px 0 #5393aa , 
    35px 35px 0 #5393aa , 
    36px 36px 0 #5393aa , 
    37px 37px 0 #5393aa , 
    38px 38px 0 #5393aa , 
    39px 39px 0 #5393aa , 
    40px 40px 0 #5393aa ;
}

.social-net li:nth-child(3) i {
    background-color: #a57d60;
    overflow: hidden;
    text-shadow: 3px 3px 0 #89634a , 
    4px 4px 0 #89634a , 
    5px 5px 0 #89634a , 
    6px 6px 0 #89634a , 
    7px 7px 0 #89634a , 
    8px 8px 0 #89634a , 
    9px 9px 0 #89634a , 
    10px 10px 0 #89634a , 
    11px 11px 0 #89634a , 
    12px 12px 0 #89634a , 
    13px 13px 0 #89634a , 
    14px 14px 0 #89634a , 
    15px 15px 0 #89634a , 
    16px 16px 0 #89634a , 
    17px 17px 0 #89634a , 
    18px 18px 0 #89634a , 
    19px 19px 0 #89634a , 
    20px 20px 0 #89634a , 
    21px 21px 0 #89634a , 
    22px 22px 0 #89634a , 
    23px 23px 0 #89634a , 
    24px 24px 0 #89634a , 
    25px 25px 0 #89634a , 
    26px 26px 0 #89634a , 
    27px 27px 0 #89634a , 
    28px 28px 0 #89634a , 
    29px 29px 0 #89634a , 
    30px 30px 0 #89634a ,
    31px 31px 0 #89634a , 
    32px 32px 0 #89634a , 
    33px 33px 0 #89634a , 
    34px 34px 0 #89634a , 
    35px 35px 0 #89634a , 
    36px 36px 0 #89634a , 
    37px 37px 0 #89634a , 
    38px 38px 0 #89634a , 
    39px 39px 0 #89634a , 
    40px 40px 0 #89634a ;
}

.social-net li:nth-child(4) i {
    background-color: #457e9b;
    overflow: hidden;
    text-shadow: 3px 3px 0 #406a7a , 
    4px 4px 0 #406a7a , 
    5px 5px 0 #406a7a , 
    6px 6px 0 #406a7a , 
    7px 7px 0 #406a7a , 
    8px 8px 0 #406a7a , 
    9px 9px 0 #406a7a , 
    10px 10px 0 #406a7a , 
    11px 11px 0 #406a7a , 
    12px 12px 0 #406a7a , 
    13px 13px 0 #406a7a , 
    14px 14px 0 #406a7a , 
    15px 15px 0 #406a7a , 
    16px 16px 0 #406a7a , 
    17px 17px 0 #406a7a , 
    18px 18px 0 #406a7a , 
    19px 19px 0 #406a7a , 
    20px 20px 0 #406a7a , 
    21px 21px 0 #406a7a , 
    22px 22px 0 #406a7a , 
    23px 23px 0 #406a7a , 
    24px 24px 0 #406a7a , 
    25px 25px 0 #406a7a , 
    26px 26px 0 #406a7a , 
    27px 27px 0 #406a7a , 
    28px 28px 0 #406a7a , 
    29px 29px 0 #406a7a , 
    30px 30px 0 #406a7a ,
    31px 31px 0 #406a7a , 
    32px 32px 0 #406a7a , 
    33px 33px 0 #406a7a , 
    34px 34px 0 #406a7a , 
    35px 35px 0 #406a7a , 
    36px 36px 0 #406a7a , 
    37px 37px 0 #406a7a , 
    38px 38px 0 #406a7a , 
    39px 39px 0 #406a7a , 
    40px 40px 0 #406a7a ;
}

.social-net li:nth-child(5) i {
    background-color: #be4f3a;
    overflow: hidden;
    text-shadow: 3px 3px 0 #993429 , 
    4px 4px 0 #993429 , 
    5px 5px 0 #993429 , 
    6px 6px 0 #993429 , 
    7px 7px 0 #993429 , 
    8px 8px 0 #993429 , 
    9px 9px 0 #993429 , 
    10px 10px 0 #993429 , 
    11px 11px 0 #993429 , 
    12px 12px 0 #993429 , 
    13px 13px 0 #993429 , 
    14px 14px 0 #993429 , 
    15px 15px 0 #993429 , 
    16px 16px 0 #993429 , 
    17px 17px 0 #993429 , 
    18px 18px 0 #993429 , 
    19px 19px 0 #993429 , 
    20px 20px 0 #993429 , 
    21px 21px 0 #993429 , 
    22px 22px 0 #993429 , 
    23px 23px 0 #993429 , 
    24px 24px 0 #993429 , 
    25px 25px 0 #993429 , 
    26px 26px 0 #993429 , 
    27px 27px 0 #993429 , 
    28px 28px 0 #993429 , 
    29px 29px 0 #993429 , 
    30px 30px 0 #993429 ,
    31px 31px 0 #993429 , 
    32px 32px 0 #993429 , 
    33px 33px 0 #993429 , 
    34px 34px 0 #993429 , 
    35px 35px 0 #993429 , 
    36px 36px 0 #993429 , 
    37px 37px 0 #993429 , 
    38px 38px 0 #993429 , 
    39px 39px 0 #993429 , 
    40px 40px 0 #993429 ;
}