#map-canvas {
    height: 30rem;
}

.section-map img{
    width: 100%;
    margin: 0;
}

.tabs{
    background-color:transparent;
    height: inherit;
}


.tabs .tab a{
    color:#7b7b7b; 
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    font-family: 'denseregular';
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    &:before {
        color:#7fbd67;
        font-size: 2rem;
    }
}


.tabs .tab a:hover{
    color:#7b7b7b;    
}

.tabs .indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: transparent;
}

.tabs .tab a.active {
    background-color: #fff;
    color:#7b7b7b;
}

.location {
    & h5 {
        border-bottom: 1px solid #cccccc;
        display: inline-block;
    }
    & img {
        padding: 10%;
    }
    & .card-btn {
        text-align: center;
    }
    & .btn {
        text-transform: inherit;
        font-size: 1.4rem;
        background-color: #7fbd67;
        box-shadow: none;
        border-radius: 0;
        &:hover {
             background-color: #7fbd50 ;
        }
    }
}

.locations-links{
    background-color: #d8f0cf;
    display: block;
    height: 48px;
    & ul {
        overflow: hidden;
    }
}



.card-content{
    padding: .5rem !important;
}
.card.horizontal .card-stacked .card-content {
    font-family: 'denseregular';
    font-size: 1.2rem;
    letter-spacing: .1rem;
}

.card-content li{
    margin-bottom: 1rem;
}